<template>
  <div
    v-if="props.newsletter.icon"
    :style="`width: ${pixelSize(props.size)}px; line-height: ${pixelSize(
      props.size
    )}px; flex-basis: ${pixelSize(props.size)}px; max-height: ${pixelSize(
      props.size
    )}px;`"
    class="rounded"
  >
    <img :src="props.newsletter.icon" class="rounded" />
  </div>
  <div
    v-else
    :style="`background: ${
      props.newsletter.tint_color || '#0069FF'
    }; width: ${pixelSize(props.size)}px; line-height: ${pixelSize(
      props.size
    )}px; flex-basis: ${pixelSize(props.size)}px;`"
    :class="{
      'rounded text-xs font-black text-center': true,
      // Without this, Firefox will honor the `flex-1` attribute set on the Header label,
      // shrinking this down to 8px. This is a workaround that doesn't actually cause
      // weirdness on Safari or Chrome (unlike mucking around with `min-width`.)
      'shrink-0': true,
    }"
  >
    <span class="text-white">
      {{ props.newsletter.name ? props.newsletter.name[0] : "?" }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import { Newsletter } from "@/types/newsletter";

type Size = "large";

const pixelSize = (size: Size | undefined) => {
  switch (size) {
    case "large":
      return 32;
    default:
      return 18;
  }
};

type ObjectWithAllKeysOptional<T> = {
  [K in keyof T]?: T[K];
};

const props = defineProps<{
  newsletter: ObjectWithAllKeysOptional<
    Pick<Newsletter, "name" | "icon" | "tint_color">
  >;
  size?: Size;
}>();
</script>
